<template>
  <div class="page">
      <!-- HEADING 1 IMAGE BIG -->

      <plcd-page-heading
        :title="page.title"
        subtitle=""
        :svgbg="false"
        :src="page.img"
      >
      </plcd-page-heading>

      <section class="page-content">
        <bxs-layout>
          <div class="bar left-bar"></div>
          <div class="bar center-bar"></div>
          <div class="bar right-bar"></div>
          <bxs-row>
            <bxs-col
            cols="12"
            ds="12"
            >
              <div class="page-content__text">
                <div>{{ $translate('Clicca sulle icone sottostanti per scaricare gli allegati.', activeLang) }}</div>
                <div class="downloads-grid">
                  <a href="https://www.famprofili.it/wp-content/uploads/2024/01/2023-10-31_9705_IQNET.pdf" target="_blank">
                    <img src="/img/pdf-icon.png" alt="filepdf">
                    <span>Certificato ISO 9001:2015</span>
                  </a>
                  <a href="https://www.famprofili.it/wp-content/uploads/2024/01/2023-10-31_9705.pdf" target="_blank">
                    <img src="/img/pdf-icon.png" alt="filepdf">
                    <span>Certificato IGQ 9705</span>
                  </a>
                  <a href="https://www.famprofili.it/wp-content/uploads/2024/09/GAMMA_FERRIERA_ALTO_MILANESE_SPA_2sett2024.xlsx" target="_blank">
                    <img src="/img/pdf-icon.png" alt="filepdf">
                    <span>{{ $translate('Gamma di Produzione', activeLang) }}</span>
                  </a>
                  <a href="https://www.famprofili.it/wp-content/uploads/2024/09/gamma_fam_09_24.pdf" target="_blank">
                    <img src="/img/pdf-icon.png" alt="filepdf">
                    <span>{{ $translate('La politica della qualità', activeLang) }}</span>
                  </a>
                </div>
              </div>
            </bxs-col>
          </bxs-row>
        </bxs-layout>
      </section>

      <plcd-driving-banner
        :title="page.plcd_driving_banner_page_title"
        :to="page.plcd_driving_banner_link"
        :src="page.plcd_driving_banner_image"
      >
      </plcd-driving-banner>

  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'download',
  computed: {
    ...mapState({
        language: state => state.language,
        pages(state) {
          const languagePages = {
              it: state.pages,
              en: state.pagesEn,
              es: state.pagesEs
          }
          return languagePages[this.language] || state.pages
          },
        page() {
          const objArray = Object.values(this.pages)
          return objArray.filter(pages => pages.template === 'template-download.php')[0]
        },
        activeLang() {
          return this.$store.state.language
        }
    })
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/styles/mixins.scss';

.downloads-grid{
  margin-top: 100px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20%;
  text-align: center;

  @include breakpoint(tablet_portrait) {
    grid-template-columns: 1fr 1fr;
    margin-bottom: 120px;
  }

  span{
    font-size: 16px;
    line-height: 20px;
    display: inline-block;
  }

  img{
    @include Transition;
    width: 64px;
    margin: 0 auto 10px;
    opacity: .7;
  }

  a:hover{
    span{
      text-decoration: underline;
    }

    img{
      opacity: 1;
    }
  }
}
</style>
