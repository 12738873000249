<template>
  <div class="page">
      <!-- HEADING 1 IMAGE BIG -->

      <plcd-page-heading
        :title="page.title"
        subtitle=""
        :svgbg="false"
        :src="page.img"
      >
      </plcd-page-heading>

      <section class="page-content">
        <bxs-layout>
          <div class="bar left-bar"></div>
          <div class="bar center-bar"></div>
          <div class="bar right-bar"></div>
          <bxs-row>
            <bxs-col
            cols="12"
            ds="6"
            >
              <div class="page-content__text" v-html="page && page.content">
              </div>
            </bxs-col>
            <bxs-col
            cols="12"
            ds="6"
            >
              <div class="page-content__text">
                <bxs-photo-gallery galleryID="addGallery" :galleryImages="addGallery" />
                <bxs-photo-gallery galleryID="mainPhoto" :galleryImages="mainPhoto" />
              </div>
            </bxs-col>
          </bxs-row>
          <bxs-row class="profiles">
            <bxs-col
            cols="12"
            ds="6"
            >
              <div class="page-content__text text-center">
                <h2>{{ $translate('GAMME DI PRODUZIONE', activeLang) }}</h2>
              <bxs-figure
              src="https://www.famprofili.it/wp-content/uploads/2024/09/gamma_fam_09_24_page-0001.jpg"
              >
              </bxs-figure>
              <a href="https://www.famprofili.it/wp-content/uploads/2024/09/GAMMA_FERRIERA_ALTO_MILANESE_SPA_2sett2024.xlsx" target="_blank">
                {{ $translate('Clicca qui per scaricare la tabella in excel', activeLang) }}
              </a>
              </div>
            </bxs-col>
            <bxs-col
            cols="12"
            ds="6"
            >
              <div class="page-content__text text-center">
                <h2>{{ $translate('PROFILI SPECIALI', activeLang) }}</h2>
              <bxs-figure
              src="https://www.famprofili.it/wp-content/uploads/2024/03/tabella-profili-en.jpg"
              >
              </bxs-figure>
              <bxs-figure
              src="https://www.famprofili.it/wp-content/uploads/2024/03/6-scaled-1.jpg"
              >
              </bxs-figure>
              </div>
            </bxs-col>
          </bxs-row>
          <bxs-row class="profiles">
            <bxs-col
            cols="12"
            ds="12"
            >
              <div class="page-content__text">
                <div class="page-content__text" v-html="page && page.prodAddText">
                </div>
              </div>
            </bxs-col>
          </bxs-row>
          <bxs-row class="gallery-wrapper">
            <bxs-col
            cols="12"
            ds="12"
            >
              <div class="page-content__text">
                <bxs-photo-gallery galleryID="produzioneGallery" :galleryImages="produzioneGallery" class="floated-gallery floated-gallery--col4 produzione" />
              </div>
            </bxs-col>
          </bxs-row>
        </bxs-layout>
      </section>

      <plcd-driving-banner
        :title="page.plcd_driving_banner_page_title"
        :to="page.plcd_driving_banner_link"
        :src="page.plcd_driving_banner_image"
      >
      </plcd-driving-banner>

  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'produzione',
  computed: {
      ...mapState({
        language: state => state.language,
        pages(state) {
          const languagePages = {
              it: state.pages,
              en: state.pagesEn,
              es: state.pagesEs
          }
          return languagePages[this.language] || state.pages
          },
          page() {
            const objArray = Object.values(this.pages)
            return objArray.filter(pages => pages.template === 'template-production.php')[0]
          },
          mainPhoto() {
            const mainPhoto = [
              {
                thumbnailURL:
                  this.page.imgMid[0],
                largeURL:
                  this.page.imgFull[0],
                  width: this.page.imgFull[1],
                  height: this.page.imgFull[2]
              }
            ]
            return mainPhoto
          },
          produzioneGallery() {
            const gallery = []
            for (let i = 0; i < this.page.plcd_image_gallery.length; i++) {
              const obj = {
                thumbnailURL: this.page.plcd_image_gallery[i].thumb[0],
                largeURL: this.page.plcd_image_gallery[i].full[0],
                width: this.page.plcd_image_gallery[i].full[1],
                height: this.page.plcd_image_gallery[i].full[2]
              }
              gallery.push(obj)
            }
            return gallery
          },
          addGallery() {
            const addGallery = [
              {
                thumbnailURL:
                  this.page.imgMidProd[0],
                largeURL:
                  this.page.imgFullProd[0],
                  width: this.page.imgFullProd[1],
                  height: this.page.imgFullProd[2]
              }
            ]
            return addGallery
          }
      }),
      activeLang() {
        return this.$store.state.language
      }
  }
}
</script>

<style lang="scss" scoped>
.profiles{
  margin: 30px 0 50px;
}
</style>
